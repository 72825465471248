import { createApp, defineAsyncComponent } from "vue";
import "../../public/styles/app.css";

import SvgVue from 'svg-vue3'
import VueLazyLoad from 'vue3-lazyload'

const VueScrollTo = require('vue-scrollto')

const app = createApp({
  components: {
    ScrollButton: defineAsyncComponent(() => import('./components/ScrollButton.vue')),
    SocialIcons: defineAsyncComponent(() => import('./components/SocialMediaIcons.vue')),
    Slider: defineAsyncComponent(() => import('./components/Slider.vue')),
    PhotoGallery: defineAsyncComponent(() => import('./components/PhotoGallery.vue'))
  },

  data: () => ({
    openMenu: '',
    modalContent: '',
    scrollPosition: 0,
    popupOpen: false,
    openSidebar: ''
  }),

  computed: {
    mobileMenu () {
      return this.openMenu === 'mobileMenu'
    },
    howDoI () {
      return this.openMenu === 'howDoI'
    },
    searchBox () {
      return this.openMenu === 'searchBox'
    },
    isScrolling () {
      return this.scrollPosition > 0
    },
  },

  mounted () {
    if (window.location.hash.substring(0, 1) === "#") {
      VueScrollTo.scrollTo(window.location.hash, 300, {
        easing: 'ease-out',
        offset: -100,
        force: true,
        cancelable: true,
      })
    }

    if (localStorage.popup === true || !('popup' in localStorage)) {
      this.popupOpen = true
    } else {
      this.popupOpen = false
    }

  },

  methods: {
    toggleMenu (menu) {
      this.openMenu = (this.openMenu != menu ? menu : '')
    },

    handleScroll (event) {
      this.scrollPosition = window.pageYOffset != undefined ? window.pageYOffset : window.scrollY
    },

    closePopup (ids) {
      this.popupOpen = false
      localStorage.popup = false
      localStorage.saved = new Date().getTime()
      localStorage.silenced = ids
    },

    checkPopUp (popupIds) {

      this.checkPopupTimeout()

      let silenced = localStorage.getItem('silenced')

      popupIds.forEach(element => {
        if(silenced && !silenced.includes(element)){
          this.popupOpen = true
        }
      });

      return this.popupOpen
    },

    checkPopupTimeout () {
      let now = new Date().getTime()
      let time = 24 * 60 * 60 * 1000 // 60000 milliseconds = 1 minute
      let saved = localStorage.getItem('saved')

      if (saved && ((now - saved) > time)) {
        localStorage.popup = true
        this.popupOpen = true
      }
    },

    openVideo (video) {
      this.modalContent = (this.modalContent != video ? video : '')
    },

    closeVideo () {
      this.modalContent = false
    },

    setSidebar (sidebar) {
      this.openSidebar = sidebar
    }
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

})

app.use(VueLazyLoad)
app.use(SvgVue, {
  svgPath: 'resources/svg',
  extract: false,
  svgoSettings: [
      { removeTitle: true },
      { removeViewBox: false },
      { removeDimensions: true }
  ]
})

app.config.devtools = false

app.mount("#app")
